
import { Component, Prop, Vue } from 'vue-property-decorator';
import LeadForm from '@/components/views/NewProjectView/Lead/LeadForm.vue';
import PaymentDetails from '@/components/views/NewProjectView/Offer/PaymentDetails.vue';
import PaymentDetailsForm from '@/components/views/NewProjectView/Offer/PaymentDetailsForm.vue';
import Offer from '@/models/Offer';
import Project from '@/models/Project';


@Component({
    name: 'PaymentDetailsAndForm',
    components: {PaymentDetailsForm, PaymentDetails},
})
export default class PaymentDetailsAndForm extends Vue {
    @Prop({default: null}) private projectId!: string;
    @Prop({default: false}) private isEditMode!: boolean;
    @Prop({required: true}) private toggleLoading!: () => void;
    @Prop({required: true}) private project!: Project;
    @Prop({required: true}) private selectedOffer!: Offer;

    public async saveForm() {
        const paymentDetailsForm = this.$refs.paymentDetailsForm as LeadForm;

        await paymentDetailsForm.onSubmit();
    }
}
