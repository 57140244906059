
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Button, Popover } from 'ant-design-vue';
import {
    determineIfProductCategoryMustBeSimple,
    transformProducts,
    transformProductSystems,
} from '@/helpers/Products/ProductHelper';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { RouteNames } from '@/enums/routes/RouteNames';
import MultiplierModal from '@/components/views/NewProjectView/Offer/MultiplierModal.vue';
import { MultipositionPopupSettings } from '@/interfaces/components/configurator/MultipositionPopupSettings';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { fetchAndRegisterJsonLogicFunctionsIfNecessary } from '@/helpers/JsonLogicHelper';
import DimensionsWizard from '@/components/global/popup/DimensionsWizard.vue';

@Component({
    name: 'ProductSelector',
    components: { Popover, Button, MultiplierModal, DimensionsWizard },
})
export default class ProductSelector extends Vue {
    @Prop({ default: false }) private shouldPickerBeLocked!: boolean;
    @Prop({ required: true }) private productCategoryId!: string;
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ default: null }) private clientsPaymentTypeId!: string | null;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private projectId!: string;

    private multipositionSettings: null | MultipositionPopupSettings = null;
    private timesToMultiplyMultipositionProduct = 1;
    private isMultiplierModalVisible = false;

    private get productsByCategoryId() {
        if (this.productCategoryId === null) {
            return [];
        }

        const categoryById = ProductCategoriesRepository.getById(this.productCategoryId);
        if (categoryById == null) {
            return [];
        }

        return categoryById.products;
    }

    private get itemsToDisplay() {
        if (determineIfProductCategoryMustBeSimple(this.productCategoryId)) {
            return transformProductSystems(this.productSystems);
        }

        return transformProducts(this.productsByCategoryId);
    }

    private get productSystems() {
        return this.productsByCategoryId
            .map((product) => {
                return product.productSystems;
            })
            .flat();
    }

    private shouldShowDimensionsWizard(shouldShowDimensionsWizard: boolean) {
        return shouldShowDimensionsWizard && (this.productCategoryId === '5' || this.productCategoryId === '6');
    }

    private onProductClick(item: any) {
        const params = {
            productFormId: item.productFormId,
            productSystemId: item.productSystemId,
            productId: item.productId,
        };

        this.$router.push({
            name: RouteNames.configurator,
            // @ts-ignore
            params,
            query: {
                projectId: this.projectId,
                offerId: this.selectedOfferId,
                clientId: this.clientId,
                productSystemName: item.isProductCustom ? null : item.productSystemCode,
            },
        });
    }

    private async openMultiplierPopup(item: any, multipositionType: string, isCombinations: boolean = false) {
        // TODO: Check if quick is used anywhere, and if not, remove quick option
        this.multipositionSettings = {
            productFormId: item.productFormId,
            productId: item.productId,
            productSystemName: item.isProductCustom ? null : item.productSystemCode,
            productName: item.productName,
            productSystemId: item.productSystemId,
            multipositionType,
            isCombinations,
        };
    }

    private async openMultipositionPopup() {
        await fetchAndRegisterJsonLogicFunctionsIfNecessary();

        EventBus.$emit(EventBusEvents.openAddProductsPopup, {
            popupEvent: PopupEvents.openAddProducts,
            data: {
                ...this.multipositionSettings,
                timesToMultiply: this.timesToMultiplyMultipositionProduct,
                clientId: this.clientId,
                clientsPaymentTypeId: this.clientsPaymentTypeId,
                offerId: this.selectedOfferId,
                projectId: this.projectId,
            },
        });

        this.multipositionSettings = null;
        this.timesToMultiplyMultipositionProduct = 1;
    }

    private openDimensionsWizard(item: any) {
        const wizardData = {
            productFormId: item.productFormId,
            productSystemId: item.productSystemId,
            productId: item.productId,
            projectId: this.projectId,
            offerId: this.selectedOfferId,
            clientId: this.clientId,
            productSystemName: item.isProductCustom ? null : item.productSystemCode,
            productName: item.productName,
            clientsPaymentTypeId: this.clientsPaymentTypeId,
            customFieldValues: [{ pId: 'p740593', value: 'Dimenzije oken' }],
            title: 'Wizard for window dimensions',
        };

        EventBus.$emit(EventBusEvents.openDimensionsWizard, {
            popupEvent: PopupEvents.openDimensionsWizard,
            data: wizardData,
        });
    }

    private async openCombinationsWizardPopup(item: any) {
        const wizardData = {
            productFormId: item.productFormId,
            productSystemId: item.productSystemId,
            productId: item.productId,
            projectId: this.projectId,
            offerId: this.selectedOfferId,
            clientId: this.clientId,
            productSystemName: item.isProductCustom ? null : item.productSystemCode,
            productName: item.productName,
            clientsPaymentTypeId: this.clientsPaymentTypeId,
            title: 'Wizard for window combinations',
        };

        EventBus.$emit(EventBusEvents.openCombinationsWizard, {
            popupEvent: PopupEvents.openCombinationsWizard,
            data: wizardData,
        });
    }

    @Watch('multipositionSettings')
    private onMultiplierSettingsChange() {
        this.isMultiplierModalVisible = this.multipositionSettings != null;
    }
}
