
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import { sortOfferItemsAndTitlesByRowNumber, transformTableOfferItems } from '@/helpers/OfferItemTableHelper';
import OfferItemsTable from '@/components/views/project/OfferItemsTable.vue';
import { prepareAndEditOfferItem, updateOfferTitle } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import ProductsActions from '@/components/views/NewProjectView/Offer/ProductsActions.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Offer from '@/models/Offer';
import TotalPriceRow from '@/components/views/NewProjectView/Offer/TotalPriceRow.vue';
import { Icon, Spin } from 'ant-design-vue';
import ProductPicker from '@/components/views/NewProjectView/Offer/ProductPicker.vue';
import { UserRightsEnum } from '@/enums/global/UserRights';
import AddOfferTitles from '@/components/views/NewProjectView/Offer/AddOfferTitles.vue';
import UserGroupAPI from '@/api/UserGroupAPI';

@Component({
    name: 'ProductsContainer',
    components: {
        ProductPicker,
        TotalPriceRow,
        ProductsActions,
        OfferItemsTable,
        Spin,
        Icon,
        AddOfferTitles,
    },
})
export default class ProductsContainer extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ required: true }) private lockEditing!: boolean;

    private isEditMode = false;
    private isLoading = false;
    private showProductsScreen = true;

    private get selectedOfferItems() {
        const offerItemTableRef = this.$refs.offerItemsTable as OfferItemsTable | null;

        if (offerItemTableRef == null) {
            return [];
        }

        return offerItemTableRef.selectedOfferItems;
    }

    private get clientsPaymentTypeId() {
        if (this.project == null || this.project.client == null || this.project.client.paymentType == null) {
            return null;
        }

        return this.project.client.paymentType.id;
    }

    private get client() {
        if (this.project == null) {
            return null;
        }
        return this.project.client;
    }

    private get offerItems() {
        if (this.selectedOffer == null) {
            return [];
        }

        return this.selectedOffer.offerItems;
    }

    private get offerItemsAndOfferTitles() {
        return sortOfferItemsAndTitlesByRowNumber(this.selectedOffer);
    }

    private get dataSource() {
        return transformTableOfferItems(this.offerItemsAndOfferTitles, this.projectId);
    }

    private get offerItemsErrorState() {
        return this.$store.getters['configurator/offerErrorState'];
    }

    private onToggleEditing() {
        this.isEditMode = !this.isEditMode;
    }

    private onToggleLoading() {
        this.isLoading = !this.isLoading;
    }

    private async onEditOfferItem(offerItemId: string) {
        if (this.selectedOffer == null) {
            return;
        }

        const currentUserGroupId = String(this.$store.getters['jwtData/currentUserGroup']);
        let clientId = this.client ? this.client.id : null;
        if (String(this.project.userGroupId) !== currentUserGroupId) {
            // UserGroupRepository.getById(this.project.userGroupId);
            const userGroup = await UserGroupAPI.getById(String(this.project.userGroupId));
            clientId = userGroup?.data?.client?.id ?? null;
        }
        await prepareAndEditOfferItem({
            offerItemId,
            selectedOfferId: this.selectedOffer.id,
            projectId: this.projectId,
            clientsPaymentTypeId: this.clientsPaymentTypeId,
            clientId,
            offerItems: this.offerItems,
        });
    }

    private async updateSelectedOfferAndRemoveEditMode() {
        if (this.selectedOffer == null) {
            return;
        }

        try {
            await Offer.getForOffersTab(this.selectedOffer.id);
        } catch {
            return Promise.reject();
        } finally {
            this.onToggleEditing();
        }
    }

    private created() {
        // todo - should be refactored so the form updates this
        EventBus.$on(
            EventBusEvents.finishCreatingOfferItemPriceManipulations,
            this.updateSelectedOfferAndRemoveEditMode
        );
    }

    private beforeDestroy() {
        EventBus.$off(
            // todo - should be refactored so the form updates this
            EventBusEvents.finishCreatingOfferItemPriceManipulations,
            this.updateSelectedOfferAndRemoveEditMode
        );
    }
    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get canUserViewPurchasePrice() {
        return this.userRights.includes(UserRightsEnum.VIEW_PURCHASE_PRICE);
    }
}
