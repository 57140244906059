
import { Component, Prop, Vue } from 'vue-property-decorator';
import ArrowInformationIcon from '@/icons/ArrowInformationIcon.vue';
import { OfferStates } from '@/enums/global/OfferStates';
import PriceManipulationHandler from '@/components/views/NewProjectView/Offer/PriceManipulationHandler.vue';
import Offer from '@/models/Offer';
import Project from '@/models/Project';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'TotalPriceRow',
    components: {
        PriceManipulationHandler,
        ArrowInformationIcon,
        DataTable,
        Column,
    },
})
export default class TotalPriceRow extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ default: false }) private canUserViewPurchasePrice!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ required: true }) private project!: Project | null;

    private get totalPurchasePrice() {
        if (this.selectedOffer == null || !this.canUserViewPurchasePrice) {
            return null;
        }
        if (this.selectedOffer.state === OfferStates.IMPORTED && this.selectedOffer.importedPrice) {
            return this.selectedOffer.importedPrice;
        } else {
            return this.selectedOffer.purchaseOfferPrice.priceWithTax;
        }
    }

    private get totalSellingPrice() {
        if (this.selectedOffer == null) {
            return null;
        }

        return this.selectedOffer.sellingOfferPrice.priceWithTax;
    }

    private mounted() {
        if (this.canUserViewPurchasePrice) {
            EventBus.$on('resize', this.updateFinalPriceWidth);

            this.updateFinalPriceWidth();
        }
    }

    private updateFinalPriceWidth() {
        const span = this.$refs.purchasePrice as any;

        let el = document.getElementsByClassName('get-width') as any;

        if (el.length) {
            el = el[0];

            if (el.offsetWidth) {
                if (span) {
                    span.style.width = el.offsetWidth + 'px';
                }
            } else {
                setTimeout(this.updateFinalPriceWidth, 300);
            }
        } else {
            setTimeout(this.updateFinalPriceWidth, 300);
        }
    }
}
