import PurchasePriceManipulation from '@/models/PurchasePriceManipulation';

export default class PurchasePriceManipulationRepository {
    public static getAll() {
        return PurchasePriceManipulation.query().withAll().get();
    }

    public static getById(id: string) {
        return PurchasePriceManipulation.query().whereId(id).withAll().first();
    }

    public static delete(id: string) {
        return PurchasePriceManipulation.delete(id);
    }

    public static getByOfferId(offerId: string) {
        return PurchasePriceManipulation.query().withAll().where((priceManipulation: PurchasePriceManipulation) => {
            return priceManipulation.offer_id === offerId;
        }).get();
    }
}
