<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0484 12.5875L12.2203 7.8125H12.5C12.725 7.8125 12.9062 7.63125 12.9062 7.40625V4.40625C12.9062 4.18125 12.725 4 12.5 4H8.65625V1.15625C8.65625 0.93125 8.475 0.75 8.25 0.75H5.75C5.525 0.75 5.34375 0.93125 5.34375 1.15625V4H1.5C1.275 4 1.09375 4.18125 1.09375 4.40625V7.40625C1.09375 7.63125 1.275 7.8125 1.5 7.8125H1.77969L0.951563 12.5875C0.946875 12.6109 0.945312 12.6344 0.945312 12.6562C0.945312 12.8813 1.12656 13.0625 1.35156 13.0625H12.6484C12.6719 13.0625 12.6953 13.0609 12.7172 13.0562C12.9391 13.0187 13.0875 12.8078 13.0484 12.5875ZM2.1875 5.09375H6.4375V1.84375H7.5625V5.09375H11.8125V6.71875H2.1875V5.09375ZM9.5 11.9688V9.53125C9.5 9.4625 9.44375 9.40625 9.375 9.40625H8.625C8.55625 9.40625 8.5 9.4625 8.5 9.53125V11.9688H5.5V9.53125C5.5 9.4625 5.44375 9.40625 5.375 9.40625H4.625C4.55625 9.40625 4.5 9.4625 4.5 9.53125V11.9688H2.16875L2.87344 7.90625H11.125L11.8297 11.9688H9.5Z" fill="#262626"/>
    </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'BrushIcon',
})
export default class BrushIcon extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
