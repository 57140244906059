
import { Component, Prop, Vue } from 'vue-property-decorator';
import Offer from '@/models/Offer';

@Component({
    name: 'OrderNotesDetails',
    components: {},
})
export default class OrderNotesDetails extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
}
