
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Tooltip } from 'ant-design-vue';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { prepareAndOpenMultipositonPopup } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import Offer from '@/models/Offer';
import { AxiosError } from 'axios';

@Component({
    name: 'OpenMultipositionPopup',
    components: {
        Tooltip,
        Button,
    },
})
export default class OpenMultipositionPopup extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ default: [] }) private offerItems!: OfferItem[];
    @Prop({ default: [] }) private selectedOfferItems!: TransformedTableOfferItem[];
    @Prop({ default: [] }) private offerItemsAndOfferTitles!: Array<OfferItem | OfferTitle>;
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ default: null }) private clientsPaymentTypeId!: string | null;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private async onOpenMultipositionPopup() {
        if (this.selectedOffer == null) {
            return;
        }
        try {
            await prepareAndOpenMultipositonPopup({
                offerItems: this.offerItems,
                offerItemsAndOfferTitles: this.offerItemsAndOfferTitles,
                clientId: this.clientId,
                clientsPaymentTypeId: this.clientsPaymentTypeId,
                projectId: this.projectId,
                selectedOfferId: this.selectedOffer.id,
                selectedOfferItems: this.selectedOfferItems,
            });
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
        }
    }
}
