
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import { Button, DatePicker, Divider, Form, InputNumber, Select } from 'ant-design-vue';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import BankAccountRepository from '@/repositories/BankAccountRepository';
import {
    fetchPaymentDetailsDropdownOptions,
    getPaymentDetailsDecoratorRules,
    validateAndSubmitPaymentDetails,
} from '@/helpers/OfferHelper';
import { PaymentDetailsFormFields } from '@/enums/components/ProjectNew/PaymentDetailsFormFields';
import PaymentType from '@/models/PaymentType';
import Offer from '@/models/Offer';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { AxiosError } from 'axios';

@Component({
    name: 'PaymentDetailsForm',
    components: {
        Form,
        FormItem: Form.Item,
        Divider,
        DatePicker,
        InputNumber,
        Select,
        SelectOption: Select.Option,
        Button,
    },
})
export default class PaymentDetailsForm extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: true }) private toggleLoading!: () => void;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    private paymentDetailsFormFields = PaymentDetailsFormFields;
    private checkIfPaymentTypeIsExpired = PaymentType.checkIfPaymentTypeIsExpired;

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }

        return this.project.state === ProjectStates.ORDER;
    }

    private get isOfferLocked() {
        if (this.selectedOffer == null) {
            return false;
        }
        return this.selectedOffer.offerPdf != null;
    }

    private get form() {
        return this.$form.createForm(this, {
            name: 'paymentDetailsForm',
        } as IformCreateOption);
    }

    private get selectedPaymentType() {
        return PaymentTypeRepository.getById(this.form.getFieldValue(PaymentDetailsFormFields.PAYMENT_TYPE));
    }

    private get decoratorRules() {
        return getPaymentDetailsDecoratorRules(this.project, this.selectedOffer);
    }

    private get paymentTypes() {
        return PaymentTypeRepository.getAllWithArchived();
    }

    private get bankAccounts() {
        return BankAccountRepository.getAllWithFormattedName();
    }

    private get locale() {
        return getDatepickerLocale();
    }

    public async onSubmit(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        if (this.selectedOffer == null) {
            throw new Error('A selected offer should exist');
        }

        try {
            await validateAndSubmitPaymentDetails({
                projectId: this.projectId,
                offerId: this.selectedOffer.id,
                form: this.form,
            });
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private async created() {
        this.toggleLoading();
        await fetchPaymentDetailsDropdownOptions();
        this.toggleLoading();
    }
}
