
import { Component, Vue } from 'vue-property-decorator';
import { Skeleton } from 'ant-design-vue';


@Component({
    name: 'ProductsSkeleton',
    components: {Skeleton},
})
export default class ProductsSkeleton extends Vue {
}
