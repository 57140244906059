
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Divider } from 'ant-design-vue';
import AdditionalAddress from '@/components/views/NewProjectView/Lead/AdditionalAddress.vue';
import { AddressParentRelationshipNames } from '@/enums/components/ProjectNew/AddressParentRelationshipNames';
import Offer from '@/models/Offer';

@Component({
    name: 'BillingAndDeliveryAddresses',
    components: { Divider, AdditionalAddress },
})
export default class BillingAndDeliveryAddresses extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private addressParentRelationshipNames = AddressParentRelationshipNames;

    private get parentRelationshipPayload() {
        if (this.selectedOffer == null) {
            return null;
        }

        return {
            data: [
                {
                    type: `offers`,
                    id: this.selectedOffer.id,
                },
            ],
        };
    }
}
