
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import { Checkbox, Divider } from 'ant-design-vue';
import { formatCurrency } from '@/helpers/NumberFormatter';
import Offer from '@/models/Offer';

@Component({
    name: 'InstallationAndDeliveryDetails',
    components: { TwoRowWithTag, Checkbox, Divider },
})
export default class InstallationAndDeliveryDetails extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private get montage() {
        if (this.selectedOffer == null) {
            return null;
        }
        return this.selectedOffer.offerMontage;
    }

    private get montagePrice() {
        return this.montage ? formatCurrency(this.montage.getPrice, 2, 2) : '-';
    }
}
