
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Tooltip } from 'ant-design-vue';

@Component({
    name: 'EnableEditMode',
    components: { Tooltip, Button },
})
export default class EnableEditMode extends Vue {
    @Prop({ required: true }) private onToggleEditing!: () => void;
    @Prop({ default: false }) private disabled!: boolean;
}
