<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9173 9.06833C12.3499 8.04053 12.4495 6.90301 12.2021 5.81569C11.9546 4.72836 11.3727 3.74593 10.538 3.0065C9.70325 2.26707 8.65779 1.80784 7.54857 1.69337C6.43934 1.5789 5.32213 1.81495 4.35401 2.36833L3.69268 1.21033C4.70443 0.632075 5.85014 0.329243 7.01548 0.332051C8.18082 0.334858 9.32505 0.643207 10.334 1.22633C13.3273 2.95433 14.474 6.65433 13.0787 9.73967L13.9733 10.2557L11.1967 11.7317L11.0867 8.589L11.9173 9.06833ZM2.08401 4.931C1.65144 5.9588 1.55187 7.09632 1.79929 8.18364C2.04671 9.27097 2.62869 10.2534 3.46339 10.9928C4.2981 11.7323 5.34356 12.1915 6.45279 12.306C7.56201 12.4204 8.67922 12.1844 9.64734 11.631L10.3087 12.789C9.29692 13.3673 8.15122 13.6701 6.98588 13.6673C5.82053 13.6645 4.6763 13.3561 3.66734 12.773C0.674011 11.045 -0.472656 7.345 0.922677 4.25967L0.0273438 3.74433L2.80401 2.26833L2.91401 5.411L2.08334 4.93167L2.08401 4.931ZM4.66734 8.333H8.33401C8.42242 8.333 8.5072 8.29788 8.56971 8.23537C8.63223 8.17286 8.66734 8.08807 8.66734 7.99967C8.66734 7.91126 8.63223 7.82647 8.56971 7.76396C8.5072 7.70145 8.42242 7.66633 8.33401 7.66633H5.66734C5.22532 7.66633 4.80139 7.49074 4.48883 7.17818C4.17627 6.86562 4.00068 6.44169 4.00068 5.99967C4.00068 5.55764 4.17627 5.13371 4.48883 4.82115C4.80139 4.50859 5.22532 4.333 5.66734 4.333H6.33401V3.66633H7.66734V4.333H9.33401V5.66633H5.66734C5.57894 5.66633 5.49415 5.70145 5.43164 5.76396C5.36913 5.82647 5.33401 5.91126 5.33401 5.99967C5.33401 6.08807 5.36913 6.17286 5.43164 6.23537C5.49415 6.29788 5.57894 6.333 5.66734 6.333H8.33401C8.77604 6.333 9.19996 6.50859 9.51252 6.82115C9.82508 7.13371 10.0007 7.55764 10.0007 7.99967C10.0007 8.44169 9.82508 8.86562 9.51252 9.17818C9.19996 9.49074 8.77604 9.66633 8.33401 9.66633H7.66734V10.333H6.33401V9.66633H4.66734V8.333Z" fill="#262626"/>
    </svg>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';

    @Component({
        name: 'ArrowDollarIcon',
    })
    export default class ArrowDollarIcon extends Vue {
    }
</script>

<style lang="scss" scoped>

</style>
