
import { Component, Prop } from 'vue-property-decorator';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import AddressConfig from '@/models/interfaces/Address';
import SellingPriceManipulation from '@/models/SellingPriceManipulation';
import PurchasePriceManipulation from '@/models/PurchasePriceManipulation';
import { SelectedTypeOptions } from '@/interfaces/components/PriceManipulations/SelectedTypeOptions';
import {
    deletePendingPriceManipulations,
    fetchDropdownOptions,
    findPriceManipulationIndexToBeDeleted,
    transformPriceManipulation,
    updateOrCreatePriceManipulations,
} from '@/helpers/PriceManipulationHelper';
import { CreateTransformedPriceManipulationSettings } from '@/interfaces/components/PriceManipulations/CreateTransformedPriceManipulationSettings';
import { Button, Form, Icon, Input, InputNumber, Radio, Select } from 'ant-design-vue';
import ProductCategory from '@/models/ProductCategory';
import Offer from '@/models/Offer';
import PriceManipulationType from '@/models/PriceManipulationType';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { CommonUserGroups } from '@/enums/global/CommonUserGroups';

@Component({
    name: 'PriceManipulationsNew',
    components: {
        Form,
        FormItem: Form.Item,
        Icon,
        Button,
        RadioGroup: Radio.Group,
        RadioButton: Radio.Button,
        Select,
        SelectOption: Select.Option,
        InputNumber,
        Input,
    },
})
export default class PriceManipulationsNew extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private priceManipulationCategories!: Array<
        ProductCategory | { id: string; name: string }
    >;
    @Prop({ required: true }) private priceManipulationTypes!: PriceManipulationType[];
    @Prop({ required: true }) private offerPriceManipulations!: Array<
        SellingPriceManipulation | PurchasePriceManipulation
    >;
    @Prop({ required: true }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private allowedPriceManipulationTypes!: SelectedTypeOptions[];

    private priceManipulations: any = [];
    private priceManipulationsToBeDeleted: string[] = [];
    private selectedTypeOptions = SelectedTypeOptions;
    private isLoading = false;

    private get canUserEditPriceManipulations() {
        // if the user group isnt roltek, user can edit selling price manipulations
        if (this.currentUserGroup && !(<any>Object).values(CommonUserGroups).includes(this.currentUserGroup.name)) {
            return true;
        }

        // if the user is allowed to see selling price manipulations, user is always able to manipulate them
        if (this.allowedPriceManipulationTypes.includes(SelectedTypeOptions.SellingPriceManipulation)) {
            return true;
        }

        // if the user is allowed to see only purchase price manipulations, the rule determines whether it should show
        if (this.allowedPriceManipulationTypes.includes(SelectedTypeOptions.PurchasePriceManipulation)) {
            return this.canUserEditPurchasePriceManipulations;
        }

        // this won't be reached
        return false;
    }

    private get form() {
        return this.$form.createForm(this, { name: 'priceManipulation' } as IformCreateOption);
    }

    private areFieldsDisabled(priceType: SelectedTypeOptions) {
        return (
            !this.canUserEditPurchasePriceManipulations && priceType === SelectedTypeOptions.PurchasePriceManipulation
        );
    }

    private onDeletePriceManipulation(key: string) {
        const searchedPriceManipulationIndex = findPriceManipulationIndexToBeDeleted(this.priceManipulations, key);

        if (searchedPriceManipulationIndex === -1) {
            return;
        }

        const priceManipulationEntry = this.priceManipulations[searchedPriceManipulationIndex];
        if (priceManipulationEntry && priceManipulationEntry[`id-${key}`] != null) {
            this.priceManipulationsToBeDeleted.push(priceManipulationEntry[`id-${key}`]);
        }
        this.priceManipulations.splice(searchedPriceManipulationIndex, 1);
    }

    private onAddPriceManipulation(settings: CreateTransformedPriceManipulationSettings | null) {
        this.priceManipulations.push(
            transformPriceManipulation(settings, this.priceManipulations, this.allowedPriceManipulationTypes)
        );
    }

    private handleSubmit() {
        // @ts-ignore
        this.form.validateFieldsAndScroll(async (err: Error, values: AddressConfig) => {
            if (err) {
                this.$notification.error({
                    message: `${this.$t('Postoje greške u formi')}`,
                    description: `${this.$t('Provjerite i kupovnu i prodajnu cijenu!')}`,
                });
                return;
            }

            this.isLoading = true;

            try {
                await updateOrCreatePriceManipulations(this.priceManipulations, values, this.selectedOfferId as string);
                await deletePendingPriceManipulations(this.priceManipulationsToBeDeleted);
                await Offer.getForOffersTab(this.selectedOfferId as string);
            } catch (e) {
                let error;

                if (e instanceof Error) {
                    error = e.message;
                } else {
                    error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
                }

                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: error,
                });
                return;
            } finally {
                this.isLoading = false;
            }

            this.cleanUpAfterSubmitting();
            return;
        });
    }

    private cleanUpAfterSubmitting() {
        this.priceManipulationsToBeDeleted = [];
        this.$notification.success({
            message: `${this.$t('Promjene uspješne!')}`,
            description: '',
        });
        this.$emit('cancel');
    }

    private repopulatePriceManipulationsArray() {
        this.priceManipulations = [];
        this.offerPriceManipulations.map((priceManipulation) => {
            return this.onAddPriceManipulation({
                event: false,
                type: priceManipulation.priceManipulationType.id,
                category: priceManipulation.productCategory,
                value: priceManipulation.value,
                name: priceManipulation.name,
                id: priceManipulation.id,
                priceType:
                    priceManipulation instanceof SellingPriceManipulation
                        ? SelectedTypeOptions.SellingPriceManipulation
                        : SelectedTypeOptions.PurchasePriceManipulation,
            });
        });
    }

    private async mounted() {
        this.isLoading = true;

        this.repopulatePriceManipulationsArray();
        if (this.priceManipulations.length <= 0) {
            this.onAddPriceManipulation(null);
        }

        fetchDropdownOptions();
        await ProductCategory.getBasic();

        this.isLoading = false;
    }
}
