
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'CelebrationModal',
})
export default class CelebrationModal extends Vue {
    @Prop({ required: false, default: false }) private hasDiscount!: boolean;
    private visible: boolean = false;
    private showConfetti: boolean = false;
    private rotates = [
        [{ transform: 'rotate(0)' }, { transform: 'rotate(360deg) ' }],
        [{ transform: 'rotate(360deg)' }, { transform: 'rotate(0) ' }],
        [{ transform: 'rotate3d(1, 1, 1, 0deg)' }, { transform: 'rotate3d(1, 1, 1, 360deg)' }],
        [{ transform: 'rotate3d(1, 1, 1, 360deg)' }, { transform: 'rotate3d(1, 1, 1, 0deg)' }],
    ];

    private duration = {
        duration: 2000,
        iterations: Infinity,
    };

    private colors = [
        '#0CD977',
        '#FF1C1C',
        '#FF93DE',
        '#5767ED',
        '#FFC61C',
        '#8497B0',
        '#2EB872',
        '#E600E6',
        '#0050FF',
        '#0073E6',
    ];

    private async openPopup() {
        if (!this.hasDiscount) {
            return;
        }

        this.visible = true;

        await this.$nextTick();
        this.startAnimation();
        await this.$nextTick();

        this.showConfetti = true;
    }

    private closePopup() {
        this.visible = false;
        this.showConfetti = false;
        this.removeRemaningPieaces();
    }

    private startAnimation() {
        const confettiWrapper = document.querySelector('.ce-popup__confetti-wrapper');

        // Adjust this variable to change amount of confetti
        const confettiNum = 200;

        for (let i = 0; i < confettiNum; i++) {
            // Get confetti data
            const elementConfig = this.getElementData();

            // Create confetti piece
            const confetti = this.createConfetti(elementConfig);

            let randomRotate;

            if (confetti.classList.contains('circle')) {
                randomRotate = Math.random() > 0.5 ? this.rotates[2] : this.rotates[3];
            } else {
                const randomIndex = this.getRandomNum(4);
                randomRotate = this.rotates[randomIndex];
            }

            // Append confetti and start animation
            confettiWrapper?.appendChild(confetti);
            confetti.animate(randomRotate, this.duration);
        }
    }

    private removeRemaningPieaces() {
        const confettiWrapper = document.querySelector('.ce-popup__confetti-wrapper');

        if (!confettiWrapper) {
            return;
        }

        while (confettiWrapper?.firstChild) {
            confettiWrapper.removeChild(confettiWrapper.firstChild);
        }
    }

    private getElementData() {
        // Random rotation
        const randomRotation = Math.floor(Math.random() * 360);
        // Random Scale
        const randomScale = String(Math.random() * 1);
        // Random width & height between 0 and viewport
        const randomWidth = Math.floor(
            Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        );
        const randomHeight = Math.floor(
            Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500)
        );

        // Random animation-delay
        const randomAnimationDelay = this.getRandomNum(15);

        // Random colors
        const randomColor = this.colors[this.getRandomNum(this.colors.length)];

        return { randomRotation, randomScale, randomWidth, randomHeight, randomAnimationDelay, randomColor };
    }

    private createConfetti(data: any) {
        const { randomRotation, randomScale, randomWidth, randomHeight, randomAnimationDelay, randomColor } = data;

        const confetti = document.createElement('div');
        confetti.className = 'confetti';
        confetti.style.top = randomHeight + 'px';
        confetti.style.right = randomWidth + 'px';
        confetti.style.backgroundColor = randomColor;
        confetti.style.transform = 'scale(' + randomScale + ')';
        confetti.style.transform = 'skew(15deg) rotate(' + randomRotation + 'deg)';
        confetti.style.animationDelay = randomAnimationDelay + 's';

        const randomIndex = this.getRandomNum(3);

        // Randomize shape
        switch (randomIndex) {
            case 0:
                {
                    confetti.classList.add('rectangle');
                }
                break;
            case 1:
                {
                    confetti.classList.add('triangle');
                }
                break;
            case 2: {
                confetti.classList.add('circle');
            }
        }

        return confetti;
    }

    private getRandomNum(number: number) {
        return Math.floor(Math.random() * number);
    }

    private mounted() {
        EventBus.$on(EventBusEvents.showCelebrationModal, this.openPopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.showCelebrationModal, this.openPopup);
    }
}
