
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Checkbox, Divider, Form, Input } from 'ant-design-vue';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import { getOrderNotesFormDecoratorRules, validateAndSubmitOrderNotes } from '@/helpers/OfferHelper';
import { OrderNotesFormFields } from '@/enums/components/ProjectNew/OrderNotesFormFields';
import Offer from '@/models/Offer';

@Component({
    name: 'OrderNotesForm',
    components: {
        Form,
        FormItem: Form.Item,
        Checkbox,
        Divider,
        Textarea: Input.TextArea,
        Button,
        TwoRowWithTag,
    },
})
export default class OrderNotesForm extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private orderNotesFormFields = OrderNotesFormFields;

    private get form() {
        return this.$form.createForm(this, {
            name: 'orderNotesForm',
        } as IformCreateOption);
    }

    private get decoratorRules() {
        return getOrderNotesFormDecoratorRules(this.selectedOffer);
    }

    public async onSubmit(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        if (this.selectedOffer == null) {
            throw new Error('A selected offer should exist');
        }

        this.$emit('update:isLoading', true);

        try {
            await validateAndSubmitOrderNotes({
                offerId: this.selectedOffer.id,
                form: this.form,
            });
        } catch (e) {
            return Promise.reject(e);
        } finally {
            this.$emit('update:isLoading', false);
        }

        return Promise.resolve();
    }
}
