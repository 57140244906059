import { CreateTransformedPriceManipulationSettings } from '@/interfaces/components/PriceManipulations/CreateTransformedPriceManipulationSettings';
import { TransformedPriceManipulation } from '@/interfaces/components/PriceManipulations/TransformedPriceManipulation';
import { SelectedTypeOptions } from '@/interfaces/components/PriceManipulations/SelectedTypeOptions';
import SellingPriceManipulation from '@/models/SellingPriceManipulation';
import PurchasePriceManipulation from '@/models/PurchasePriceManipulation';
import AddressConfig from '@/models/interfaces/Address';
import i18n from '@/i18n';
import PurchasePriceManipulationRepository from '@/repositories/PurchasePriceManipulationRepository';
import SellingPriceManipulationRepository from '@/repositories/SellingPriceManipulationRepository';
import PriceManipulationType from '@/models/PriceManipulationType';
import { notification } from 'ant-design-vue';
import store from '@/store';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { CommonUserGroups } from '@/enums/global/CommonUserGroups';

export function transformPriceManipulation(
    settings: CreateTransformedPriceManipulationSettings | null,
    priceManipulations: TransformedPriceManipulation[],
    allowedPriceManipulationTypes: SelectedTypeOptions[]
) {
    const key = calculateKey(priceManipulations);
    const priceManipulationObject: any = {
        key,
    };

    priceManipulationObject[`type-${key}`] = '';
    priceManipulationObject[`categories-${key}`] = '0';
    priceManipulationObject[`value-${key}`] = 0;
    priceManipulationObject[`name-${key}`] = '';
    priceManipulationObject[`id-${key}`] = null;
    priceManipulationObject[`price-type-${key}`] = allowedPriceManipulationTypes[0];

    if (settings != null) {
        priceManipulationObject[`type-${key}`] = settings.type || '';
        priceManipulationObject[`categories-${key}`] = settings.category ? settings.category.id : '0';
        priceManipulationObject[`value-${key}`] = settings.value || 0;
        priceManipulationObject[`name-${key}`] = settings.name || '';
        priceManipulationObject[`id-${key}`] = settings.id || null;
        priceManipulationObject[`price-type-${key}`] =
            settings.priceType == null ? allowedPriceManipulationTypes[0] : settings.priceType;
    }

    return priceManipulationObject;
}

function calculateKey(priceManipulations: TransformedPriceManipulation[]) {
    let key = 1;

    if (priceManipulations.length >= 1) {
        const maxKeyValue = Math.max.apply(
            Math,
            priceManipulations.map((priceManipulation) => {
                return priceManipulation.key;
            })
        );
        key = maxKeyValue + 1;
    }

    return key;
}

export async function updateOrCreatePriceManipulations(
    priceManipulations: TransformedPriceManipulation[],
    values: AddressConfig,
    selectedOfferId: string
) {
    try {
        const keys = priceManipulations.map((priceManipulation: any) => priceManipulation.key);
        for await (const key of keys) {
            const priceManipulationObject = {
                productCategory: values[`categories-${key}`],
                priceManipulationType: values[`priceManipulationType-${key}`],
                name: values[`name-${key}`],
                value: values[`value-${key}`],
                offer: selectedOfferId,
            };

            const priceManipulationEntry = priceManipulations.filter(
                (priceManipulation: any) => priceManipulation.key === key
            )[0];

            const priceManipulationEntity =
                values[`price-type-${key}`] === SelectedTypeOptions.SellingPriceManipulation
                    ? SellingPriceManipulation
                    : PurchasePriceManipulation;

            if (priceManipulationEntry && priceManipulationEntry[`id-${key}`] != null) {
                await priceManipulationEntity.updateExisting(
                    priceManipulationEntry[`id-${key}`],
                    priceManipulationObject
                );
            } else {
                await priceManipulationEntity.createNew(priceManipulationObject);
            }
        }
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

export async function deletePendingPriceManipulations(priceManipulationIdsToBeDeleted: string[]) {
    for await (const priceManipulationId of priceManipulationIdsToBeDeleted) {
        const priceManipulationObject =
            PurchasePriceManipulationRepository.getById(priceManipulationId) ||
            SellingPriceManipulationRepository.getById(priceManipulationId);
        const priceManipulationEntity =
            priceManipulationObject instanceof SellingPriceManipulation
                ? SellingPriceManipulation
                : PurchasePriceManipulation;
        await priceManipulationEntity.deleteExisting(priceManipulationId);
    }
}

export async function fetchDropdownOptions() {
    try {
        await PriceManipulationType.getAll();
    } catch (e) {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: (e as Error).message,
        });
        return;
    }
}

export function findPriceManipulationIndexToBeDeleted(priceManipulations: TransformedPriceManipulation[], key: string) {
    return priceManipulations.findIndex((priceManipulation: any) => {
        return priceManipulation.key === key;
    });
}

export function determineAllowedPriceManipulationTypes(isProjectReadOnly: boolean, currentUserGroupName: string) {
    const allowedPriceManipulations = [];

    if (canUserViewPurchasePriceManipulations()) {
        allowedPriceManipulations.push(SelectedTypeOptions.PurchasePriceManipulation);
    }

    if ((<any>Object).values(CommonUserGroups).includes(currentUserGroupName)) {
        if (isProjectReadOnly) {
            allowedPriceManipulations.push(SelectedTypeOptions.SellingPriceManipulation);
        }
    } else {
        allowedPriceManipulations.push(SelectedTypeOptions.SellingPriceManipulation);
    }

    return allowedPriceManipulations;
}

function getUserRights() {
    return store.getters['jwtData/userRights'];
}

function canUserViewPurchasePriceManipulations() {
    return getUserRights().includes(UserRightsEnum.VIEW_PURCHASE_PRICE_MANIPULATIONS);
}

function canUserEditPurchasePriceManipulations() {
    return getUserRights().includes(UserRightsEnum.EDIT_PURCHASE_PRICE_MANIPULATIONS);
}
