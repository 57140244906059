
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popconfirm, Tooltip } from 'ant-design-vue';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import BrushIcon from '@/icons/BrushIcon.vue';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { deleteOfferItemPriceManipulations } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import Project from '@/models/Project';

@Component({
    name: 'DeleteOfferItemPriceManipulations',
    components: { Tooltip, Popconfirm, BrushIcon },
})
export default class DeleteOfferItemPriceManipulations extends Vue {
    @Prop({ default: () => [] }) private selectedOfferItems!: TransformedTableOfferItem[];
    @Prop({ default: () => [] }) private offerItemsAndTitles!: Array<OfferItem | OfferTitle>;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private onToggleEditing!: () => void;
    @Prop({ required: true }) private onToggleLoading!: () => void;
    @Prop({ required: true }) private project!: Project;

    private async onDeleteOfferItemsPriceManipulations() {
        this.onToggleLoading();
        try {
            await deleteOfferItemPriceManipulations(
                this.selectedOfferItems,
                this.selectedOfferId,
                this.offerItemsAndTitles
            );
        } catch (e) {
            return;
        } finally {
            this.onToggleLoading();
        }

        this.onToggleEditing();
        this.$notification.success({
            message: this.$t('Promjene cijena su uspješno obrisane!') as string,
            description: '',
        });
    }
}
