
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Popconfirm, Tooltip } from 'ant-design-vue';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import OfferItemsTable from '@/components/views/project/OfferItemsTable.vue';
import { deleteOfferItemsAndUpdateRowNumbers } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { AxiosError } from 'axios';

@Component({
    name: 'DeleteOfferItems',
    components: { Tooltip, Button, Popconfirm },
})
export default class DeleteOfferItems extends Vue {
    @Prop({ default: () => [] }) private selectedOfferItems!: TransformedTableOfferItem[];
    @Prop({ required: true }) private offerItemsTableReference!: OfferItemsTable;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private onToggleEditing!: () => void;
    @Prop({ required: true }) private onToggleLoading!: () => void;

    private async onDelete() {
        this.onToggleLoading();

        try {
            await deleteOfferItemsAndUpdateRowNumbers(
                this.selectedOfferItems,
                this.selectedOfferId,
                this.offerItemsTableReference
            );
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return;
        } finally {
            this.onToggleLoading();
        }

        this.onToggleEditing();
    }
}
