
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Icon, Input, Modal, Tooltip } from 'ant-design-vue';
import { createOfferTitle, updateOfferTitle } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Offer from '@/models/Offer';

@Component({
    name: 'AddOfferTitles',
    components: { Tooltip, Icon, Modal, Button, Input },
})
export default class AddOfferTitles extends Vue {
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: null }) private offerTitles!: any[] | null;
    @Prop({ required: true }) private onToggleEditing!: () => void;

    private selectedOfferTitleId: string | null = null;
    private isModalVisible = false;
    private isLoading = false;
    private name = '';

    private closeOfferTitleModal() {
        this.isModalVisible = false;
        this.selectedOfferTitleId = null;
        this.name = '';
    }

    private openOfferTitleModal() {
        this.isModalVisible = true;
    }

    private async addOfferTitle() {
        if (this.selectedOfferId == null) {
            return;
        }

        this.isLoading = true;
        try {
            if (this.selectedOfferTitleId) {
                await updateOfferTitle(this.name, this.selectedOfferId, this.selectedOfferTitleId);
                await Offer.getForOffersTab(this.selectedOfferId);
            } else {
                await createOfferTitle(this.name, this.selectedOfferId);
            }
        } catch {
            return;
        } finally {
            this.isLoading = false;
        }

        this.closeOfferTitleModal();
        this.resetForm();
        this.onToggleEditing();
    }

    private resetForm() {
        this.name = '';
    }

    private async handleTitleChange(id: string) {
        this.openOfferTitleModal();
        this.selectedOfferTitleId = id;

        this.getCurrentOfferTitle();
        this.name;
    }

    private getCurrentOfferTitle() {
        if (this.selectedOfferTitleId == null || this.offerTitles == null) {
            return null;
        }

        const currentOfferTitle = this.offerTitles.find((offerTitle) => offerTitle.id === this.selectedOfferTitleId);
        this.name = currentOfferTitle.description;
    }

    private mounted() {
        EventBus.$on(EventBusEvents.openAddOfferTitlePopup, this.handleTitleChange);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.openAddOfferTitlePopup, this.handleTitleChange);
    }
}
