import SellingPriceManipulation from '@/models/SellingPriceManipulation';

export default class SellingPriceManipulationRepository {
    public static getAll() {
        return SellingPriceManipulation.query().withAll().get();
    }

    public static getById(id: string) {
        return SellingPriceManipulation.query().whereId(id).withAll().first();
    }

    public static delete(id: string) {
        return SellingPriceManipulation.delete(id);
    }

    public static getByOfferId(offerId: string) {
        return SellingPriceManipulation.query().withAll().where((priceManipulation: SellingPriceManipulation) => {
            return priceManipulation.offer_id === offerId;
        }).get();
    }
}
