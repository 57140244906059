
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Icon, Input, Modal, Tooltip } from 'ant-design-vue';
import { determineAllowedPriceManipulationTypes } from '@/helpers/PriceManipulationHelper';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import Project from '@/models/Project';
import SellingPriceManipulationRepository from '@/repositories/SellingPriceManipulationRepository';
import PurchasePriceManipulationRepository from '@/repositories/PurchasePriceManipulationRepository';
import { SelectedTypeOptions } from '@/interfaces/components/PriceManipulations/SelectedTypeOptions';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import PriceManipulationTypeRepository from '@/repositories/PriceManipulationTypeRepository';
import Offer from '@/models/Offer';
import PriceManipulationsNew from '@/components/views/NewProjectView/Offer/PriceManipulationsNew.vue';
import { ModalWidth } from '@/enums/global/ModalWidth';

@Component({
    name: 'PriceManipulationsModal',
    components: {PriceManipulationsNew, Tooltip, Icon, Modal, Button, Input},
})
export default class PriceManipulationsModal extends Vue {
    @Prop({required: true}) private projectId!: string;
    @Prop({default: false}) private isModalVisible!: boolean;
    @Prop({required: true}) private allowedPriceManipulationTypes!: SelectedTypeOptions[];
    @Prop({required: true}) private project!: Project;
    @Prop({required: true}) private selectedOffer!: Offer | null;

    private modalWidth = ModalWidth;
    private isLoading = false;


    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get priceManipulationTypes() {
        return PriceManipulationTypeRepository.getAll();
    }

    private get priceManipulationCategories() {
        return [...ProductCategoriesRepository.getAll(), {
            id: '0',
            name: this.$t('Sve') as string,
        }];
    }

    private get priceManipulations() {
        if (this.selectedOffer == null || this.currentUserGroup == null) {
            return [];
        }

        const allowedPriceManipulations = determineAllowedPriceManipulationTypes(
            this.project.readOnly,
            this.currentUserGroup.name);
        const sellingPriceManipulations = SellingPriceManipulationRepository.getByOfferId(this.selectedOffer.offerId);
        const purchasePriceManipulations = PurchasePriceManipulationRepository.getByOfferId(this.selectedOffer.offerId);

        return allowedPriceManipulations.map((priceManipulationType) => {
            switch (priceManipulationType) {
                case SelectedTypeOptions.SellingPriceManipulation:
                    return [...sellingPriceManipulations];
                case SelectedTypeOptions.PurchasePriceManipulation:
                    return [...purchasePriceManipulations];
                default:
                    return [];
            }
        }).flat();
    }

    private closePriceManipulationsModal() {
        this.$emit('update:isModalVisible', false);
    }


}
