
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Icon } from 'ant-design-vue';
import ProductCategoriesAndSearch from '@/components/views/NewProjectView/Offer/ProductCategoriesAndSearch.vue';
import OfferItem from '@/models/OfferItem';
import ProductCategory from '@/models/ProductCategory';

@Component({
    name: 'ProductPicker',
    components: {
        ProductCategoriesAndSearch,
        Button,
        Icon,
    },
})
export default class ProductPicker extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private clientsPaymentTypeId!: string | null;
    @Prop({ required: true }) private offerItems!: OfferItem[];
    @Prop({ default: null }) private clientId!: string | null;

    private isAddingMode = false;
    private isLoading = false;

    private async toggleIsAddingMode() {
        this.isAddingMode = !this.isAddingMode;

        if (this.isAddingMode) {
            this.isLoading = true;
            await ProductCategory.getAll(); // WithoutIcons
            this.isLoading = false;
        }
    }
}
