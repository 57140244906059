<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
        <path fill="#2658a5"
              fill-rule="nonzero"
              d="M14.367 5.425l.701.701L3.992 17.203l2.805 2.805L17.874 8.932l.701.7L7.353 20.856a.496.496 0 0 1-.35.145H3.496A.496.496 0 0 1 3 20.504v-3.507c0-.131.052-.257.145-.35L14.367 5.425zm1.403-1.403c1.362-1.363 2.845-1.363 4.208 0s1.363 2.846 0 4.208a.496.496 0 0 1-.701 0l-2.855-2.855-.652-.652a.496.496 0 0 1 0-.701z"/>
    </svg>


</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'EditIcon',
})
export default class EditIcon extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
