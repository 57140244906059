
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { Tooltip } from 'ant-design-vue';
import ArrowDollarIcon from '@/icons/ArrowDollarIcon.vue';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { filterProductsFromOfferItems } from '@/helpers/OfferItemTableHelper';

@Component({
    name: 'OpenOfferItemPriceManipulationModal',
    components: {
        Tooltip,
        ArrowDollarIcon,
    },
})
export default class OpenOfferItemPriceManipulationModal extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: () => [] }) private selectedOfferItems!: TransformedTableOfferItem[];

    private openAddOfferItemPriceManipulationModal() {
        if (this.selectedOfferItems.length <= 0) {
            return;
        }

        EventBus.$emit(EventBusEvents.onOfferItemPriceManipulation, {
            popupEvent: PopupEvents.offerItemPriceManipulation,
            data: {
                selectedOfferItems: (
                    filterProductsFromOfferItems(this.selectedOfferItems, true) as TransformedTableOfferItem[]
                ).map((offerItem: TransformedTableOfferItem) => offerItem.id),
            },
        });
    }
}
