
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { generateProductCategoryOptions } from '@/helpers/Products/ProductHelper';
import { Tabs } from 'ant-design-vue';
import ProductSelector from '@/components/views/NewProjectView/Offer/ProductSelector.vue';
import ProductsSkeleton from '@/components/views/NewProjectView/Offer/ProductsSkeleton.vue';
import ProductAndMaterialSearch from '@/components/views/NewProjectView/Offer/ProductAndMaterialSearch.vue';
import OfferItem from '@/models/OfferItem';

@Component({
    name: 'ProductCategoriesAndSearch',
    components: { ProductsSkeleton, ProductSelector, Tabs, TabPane: Tabs.TabPane, ProductAndMaterialSearch },
})
export default class ProductCategoriesAndSearch extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ default: null }) private clientsPaymentTypeId!: string | null;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ required: true }) private offerItems!: OfferItem[];

    private selectedProductCategory = '';
    private isLoaded = false;

    private get isSeletedCategoryMaterials() {
        if (this.selectedProductCategory == null) {
            return false;
        }

        const categoryById = ProductCategoriesRepository.getById(this.selectedProductCategory);

        if (categoryById == null) {
            return false;
        }

        return categoryById.code === 'MATERIALS';
    }

    private get productCategoryOptions(): RadioButtonGroupOptions[] {
        return generateProductCategoryOptions(this.productCategories, true);
    }

    private get productCategories() {
        return ProductCategoriesRepository.getAll();
    }

    @Watch('productCategoryOptions', { deep: true })
    private onProductCategoryOptionsChange() {
        if (this.productCategoryOptions.length > 0 && !this.isLoaded) {
            this.isLoaded = true;
            this.selectedProductCategory = this.productCategoryOptions[0].value;
        }
    }
}
