
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, InputNumber, Modal, Tooltip } from 'ant-design-vue';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import { duplicateSelectedOfferItems } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import OfferItemsTable from '@/components/views/project/OfferItemsTable.vue';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { AxiosError } from 'axios';

@Component({
    name: 'HandleOfferItemDuplication',
    components: { Tooltip, Button, Modal, InputNumber },
})
export default class HandleOfferItemDuplication extends Vue {
    @Prop({ required: true }) private offerItemsTableReference!: OfferItemsTable;
    @Prop({ default: () => [] }) private selectedOfferItems!: TransformedTableOfferItem[];
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private onToggleEditing!: () => void;

    private isModalVisible = false;
    private timesToDuplicate = 1;
    private isLoading = false;

    private async onDuplicate() {
        this.isLoading = true;

        try {
            await duplicateSelectedOfferItems(
                this.selectedOfferItems,
                this.selectedOfferId,
                this.offerItemsTableReference,
                this.timesToDuplicate
            );
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return;
        }

        this.closeDuplicateModal();
        this.onToggleEditing();
    }

    private openDuplicateModal() {
        if (this.selectedOfferItems.length <= 0) {
            return;
        }

        this.isModalVisible = true;
    }

    private closeDuplicateModal() {
        this.isModalVisible = false;
        this.isLoading = false;
        this.timesToDuplicate = 1;
    }
}
