
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Icon, Popconfirm, Tooltip } from 'ant-design-vue';
import Offer from '@/models/Offer';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { AxiosError } from 'axios';

@Component({
    name: 'ClearMontage',
    components: { Tooltip, Icon, Popconfirm },
})
export default class ClearMontage extends Vue {
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private async onClearMontage() {
        this.$emit('update:loadingState', true);
        this.$emit('update:editState', true);

        if (this.selectedOffer == null) {
            return;
        }
        try {
            await Offer.clearMontage(this.selectedOffer.id);
            await Offer.getForOffersTab(this.selectedOffer.id);
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return;
        } finally {
            this.$emit('update:editState', false);
            this.$emit('update:loadingState', false);
        }
    }
}
