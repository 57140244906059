
import { Component, Prop } from 'vue-property-decorator';
import { Button, Icon, Input, Modal, Tooltip } from 'ant-design-vue';
import PriceManipulationsModal from '@/components/views/NewProjectView/Offer/PriceManipulationsModal.vue';
import { determineAllowedPriceManipulationTypes } from '@/helpers/PriceManipulationHelper';
import Offer from '@/models/Offer';
import Project from '@/models/Project';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import ArrowDollarIcon from '@/icons/ArrowDollarIcon.vue';

@Component({
    name: 'PriceManipulationHandler',
    components: {PriceManipulationsModal, Tooltip, Icon, Modal, Button, Input, ArrowDollarIcon},
})
export default class PriceManipulationHandler extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({required: true}) private projectId!: string;
    @Prop({required: true}) private project!: Project;
    @Prop({required: true}) private selectedOffer!: Offer | null;
    private isModalVisible = false;

    private get allowedPriceManipulationTypes() {
        if (this.selectedOffer == null || this.currentUserGroup == null) {
            return [];
        }

        return determineAllowedPriceManipulationTypes(this.project.readOnly, this.currentUserGroup.name);
    }

    private openPriceManipulationModal() {
        this.isModalVisible = true;
    }

}
