var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"c-price-manipulation-form__wrapper",class:{ isLoading: _vm.isLoading }},[(_vm.priceManipulations.length <= 0)?_c('p',{staticClass:"c-price-manipulation-form__empty-message"},[_vm._v(" "+_vm._s(_vm.$t('nemaPromjenaCijena'))+" ")]):_c('Form',{attrs:{"form":_vm.form,"layout":"vertical"}},_vm._l((_vm.priceManipulations),function(priceManipulation){return _c('div',{key:priceManipulation.key,staticClass:"c-price-manipulation-form",attrs:{"data-test":"price-manipulation-form"}},[_c('FormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowedPriceManipulationTypes.length === 2 && _vm.canUserEditPurchasePriceManipulations),expression:"allowedPriceManipulationTypes.length === 2 && canUserEditPurchasePriceManipulations"}],staticClass:"c-price-manipulation-form__item c-price-manipulation-form__item--radio u-b1"},[_c('RadioGroup',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `price-type-${priceManipulation.key}`,
                            {
                                initialValue: priceManipulation[`price-type-${priceManipulation.key}`],
                                rules: [{ required: true, message: _vm.$t('Tip cijene je obavezan!') }],
                            },
                        ]),expression:"[\n                            `price-type-${priceManipulation.key}`,\n                            {\n                                initialValue: priceManipulation[`price-type-${priceManipulation.key}`],\n                                rules: [{ required: true, message: $t('Tip cijene je obavezan!') }],\n                            },\n                        ]"}],staticClass:"c-price-manipulation-form__radio-group",class:{
                            'is-disabled': _vm.areFieldsDisabled(
                                priceManipulation[`price-type-${priceManipulation.key}`]
                            ),
                        },attrs:{"button-style":"solid"}},[_c('RadioButton',{attrs:{"value":_vm.selectedTypeOptions.SellingPriceManipulation}},[_vm._v(" "+_vm._s(_vm.$t('Prodajna cijena'))+" ")]),_c('RadioButton',{attrs:{"value":_vm.selectedTypeOptions.PurchasePriceManipulation}},[_vm._v(" "+_vm._s(_vm.$t('Kupovna cijena'))+" ")])],1)],1),_c('FormItem',{staticClass:"c-price-manipulation-form__item u-b1",attrs:{"label":_vm.$t('Tip')}},[_c('Select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `priceManipulationType-${priceManipulation.key}`,
                            {
                                initialValue: priceManipulation[`type-${priceManipulation.key}`],
                                rules: [{ required: true, message: _vm.$t('Tip je obavezan!') }],
                            },
                        ]),expression:"[\n                            `priceManipulationType-${priceManipulation.key}`,\n                            {\n                                initialValue: priceManipulation[`type-${priceManipulation.key}`],\n                                rules: [{ required: true, message: $t('Tip je obavezan!') }],\n                            },\n                        ]"}],attrs:{"disabled":_vm.areFieldsDisabled(priceManipulation[`price-type-${priceManipulation.key}`]),"placeholder":_vm.$t('Odaberite tip'),"filterOption":"","optionFilterProp":"children","show-search":"","data-test":"price-manipulation-type"}},_vm._l((_vm.priceManipulationTypes),function(priceManipulationType){return _c('SelectOption',{key:priceManipulationType.id,staticClass:"u-b1",attrs:{"value":priceManipulationType.id}},[_vm._v(" "+_vm._s(priceManipulationType.name)+" ")])}),1)],1),_c('FormItem',{staticClass:"c-price-manipulation-form__item u-b1",attrs:{"label":_vm.$t('Kategorije')}},[_c('Select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `categories-${priceManipulation.key}`,
                            {
                                initialValue: priceManipulation[`categories-${priceManipulation.key}`],
                                rules: [{ required: true, message: _vm.$t('Kategorija je obavezna!') }],
                            },
                        ]),expression:"[\n                            `categories-${priceManipulation.key}`,\n                            {\n                                initialValue: priceManipulation[`categories-${priceManipulation.key}`],\n                                rules: [{ required: true, message: $t('Kategorija je obavezna!') }],\n                            },\n                        ]"}],attrs:{"disabled":_vm.areFieldsDisabled(priceManipulation[`price-type-${priceManipulation.key}`]),"placeholder":_vm.$t('Odaberite kategorije'),"filterOption":"","optionFilterProp":"children","show-search":""}},_vm._l((_vm.priceManipulationCategories),function(priceManipulationCategory){return _c('SelectOption',{key:priceManipulationCategory.id,staticClass:"u-b1",attrs:{"value":priceManipulationCategory.id}},[_vm._v(" "+_vm._s(_vm.$t(priceManipulationCategory.name))+" ")])}),1)],1),_c('FormItem',{staticClass:"c-price-manipulation-form__item u-b1",attrs:{"label":_vm.$t('Iznos')}},[_c('InputNumber',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `value-${priceManipulation.key}`,
                            {
                                initialValue: priceManipulation[`value-${priceManipulation.key}`],
                                rules: [
                                    {
                                        required: true,
                                        message: _vm.$t('Iznos je obavezan!'),
                                        whitespace: true,
                                        pattern: /\d+(\.\d{1,2})?/,
                                        message: _vm.$t('Iznos mora biti brojčane vrijednosti'),
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `value-${priceManipulation.key}`,\n                            {\n                                initialValue: priceManipulation[`value-${priceManipulation.key}`],\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: $t('Iznos je obavezan!'),\n                                        whitespace: true,\n                                        pattern: /\\d+(\\.\\d{1,2})?/,\n                                        message: $t('Iznos mora biti brojčane vrijednosti'),\n                                    },\n                                ],\n                            },\n                        ]"}],attrs:{"disabled":_vm.areFieldsDisabled(priceManipulation[`price-type-${priceManipulation.key}`]),"decimalSeparator":",","data-test":"price-manipulation-amount"}})],1),_c('FormItem',{staticClass:"c-price-manipulation-form__item u-b1",attrs:{"label":_vm.$t('Naziv')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `name-${priceManipulation.key}`,
                            {
                                initialValue: priceManipulation[`name-${priceManipulation.key}`],
                            },
                        ]),expression:"[\n                            `name-${priceManipulation.key}`,\n                            {\n                                initialValue: priceManipulation[`name-${priceManipulation.key}`],\n                            },\n                        ]"}],attrs:{"data-test":`price-type-${priceManipulation.key}`,"disabled":_vm.areFieldsDisabled(priceManipulation[`price-type-${priceManipulation.key}`])}})],1),(_vm.canUserEditPriceManipulations)?_c('div',{staticClass:"c-price-manipulation-form__delete-wrapper"},[_c('Icon',{staticClass:"c-price-manipulation-form__delete buttonIcon",attrs:{"type":"delete"},on:{"click":function($event){return _vm.onDeletePriceManipulation(priceManipulation.key)}}})],1):_vm._e()],1)}),0),_c('Button',{staticClass:"c-price-manipulation-form__button",attrs:{"disabled":!_vm.canUserEditPriceManipulations,"type":"default"},on:{"click":() => _vm.onAddPriceManipulation(null)}},[_c('Icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('Dodaj'))+" ")],1)],1),_c('div',{staticClass:"c-price-manipulation-form__footer"},[_c('Button',{staticClass:"c-price-manipulation-form__button",attrs:{"disabled":_vm.isLoading || !_vm.canUserEditPriceManipulations,"type":"default"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t('Odustani'))+" ")]),_c('Button',{staticClass:"c-price-manipulation-form__button",attrs:{"disabled":!_vm.canUserEditPriceManipulations,"loading":_vm.isLoading,"data-test":"price-manipulation-submit","type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('Spremi'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }