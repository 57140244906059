
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import { Button, Checkbox, Divider, Form, InputNumber } from 'ant-design-vue';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import { getInstallationAndDeliveryFormDecoratorRules, validateAndSubmitMontage } from '@/helpers/OfferHelper';
import { InstallationAndDeliveryFormFields } from '@/enums/components/ProjectNew/InstallationAndDeliveryFormFields';
import Offer from '@/models/Offer';

@Component({
    name: 'InstallationAndDeliveryForm',
    components: {
        Form,
        FormItem: Form.Item,
        Checkbox,
        Divider,
        InputNumber,
        Button,
        TwoRowWithTag,
    },
})
export default class InstallationAndDeliveryForm extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ default: null }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private installationAndDeliveryFormFields = InstallationAndDeliveryFormFields;

    private get form() {
        return this.$form.createForm(this, {
            name: 'installationAndDeliveryForm',
        } as IformCreateOption);
    }

    private get decoratorRules() {
        return getInstallationAndDeliveryFormDecoratorRules(this.selectedOffer ? this.selectedOffer.montage : null);
    }

    public async onSubmit(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        if (this.selectedOffer == null) {
            throw new Error('A selected offer should exist');
        }

        this.$emit('update:isLoading', true);

        try {
            await validateAndSubmitMontage({
                montageId: this.selectedOffer.montage ? this.selectedOffer.montage.getId : undefined,
                offerId: this.selectedOffer.id,
                form: this.form,
            });
        } catch (e) {
            return Promise.reject(e);
        } finally {
            this.$emit('update:isLoading', false);
        }

        return Promise.resolve();
    }
}
