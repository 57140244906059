import Offer from '@/models/Offer';
import { showChangeSuccessNotification, showErrorNotifications } from '@/helpers/NotificationHelper';
import { fetchAndRegisterJsonLogicFunctionsIfNecessary } from '@/helpers/JsonLogicHelper';
import {
    deleteTheCorrectEntity,
    duplicateTheCorrectEntity,
    extractAndFetchProductsAndProductFormIds,
    extractOfferItemIds,
    filterProductsFromOfferItems,
    generateArraysForUpdatingRowNumbers,
    mapAllOfferItemsForUpdatingRowNumbers,
} from '@/helpers/OfferItemTableHelper';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PreMultipositionPopupSettings } from '@/interfaces/components/configurator/PreMultipositionPopupSettings';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import OfferItemsTable from '@/components/views/project/OfferItemsTable.vue';
import DataTable from 'primevue/datatable/DataTable';
import OfferItemRepository from '@/repositories/OfferItemRepository';
import { ProductTypes } from '@/enums/global/ProductTypes';
import { EditOfferItemSettings } from '@/interfaces/components/projectNew/EditOfferItemSettings';
import { ConfiguratorSettings } from '@/interfaces/components/configurator/ConfiguratorSettings';
import { RouteNames } from '@/enums/routes/RouteNames';
import router from '@/router/index';
import OfferItemPriceManipulation from '@/models/OfferItemPriceManipulation';
import { notification } from 'ant-design-vue';
import i18n from '@/i18n';
import { AxiosError } from 'axios';
import SellingPriceManipulation from '@/models/SellingPriceManipulation';
import OfferItemPrice from '@/models/OfferItemPrice';

/**
 * Toggle the allow errors flag on the offer
 * @param selectedOfferId - The offer id that needs to be updated
 * @param isOfferAllowingErrors - The current state of the allowErrors flag
 */
// export async function toggleAllowErrorsOnOffer(selectedOfferId: string, isOfferAllowingErrors: boolean) {
//     try {
//         if (!isOfferAllowingErrors) {
//             await Offer.allowErrors(selectedOfferId);
//         } else {
//             await Offer.disallowErrors(selectedOfferId);
//         }
//         EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
//     } catch (e) {
//         showErrorNotifications(e as AxiosError<any>);
//         return;
//     }

//     showChangeSuccessNotification();
// }

/**
 * Prepare the data for multiposition and open the multiposition popup with the given offer items
 * @param preMultipositionPopupSettings - All settings needed to initialize the popup
 */
export async function prepareAndOpenMultipositonPopup(preMultipositionPopupSettings: PreMultipositionPopupSettings) {
    await fetchAndRegisterJsonLogicFunctionsIfNecessary();

    const offerItemsToBeUsed = determineWhichOfferItemsWillBeUsed(
        preMultipositionPopupSettings.offerItems,
        preMultipositionPopupSettings.selectedOfferItems
    );

    const {
        offerItemIdsForUpdatingRowNumbers,
        offerItemIds,
        productFormAndProductIds,
    } = extractProductProductFormAndOfferItemsIds(
        offerItemsToBeUsed,
        preMultipositionPopupSettings.offerItemsAndOfferTitles
    );

    const popupData: PopupEventData = {
        popupEvent: PopupEvents.openMultiposition,
        data: {
            productFormAndProductIds,
            offerItemIds,
            clientId: preMultipositionPopupSettings.clientId,
            projectId: preMultipositionPopupSettings.projectId,
            offerId: preMultipositionPopupSettings.selectedOfferId,
            clientsPaymentTypeId: preMultipositionPopupSettings.clientsPaymentTypeId,
            offerItemIdsForUpdatingRowNumbers,
        },
    };

    EventBus.$emit(EventBusEvents.openAddProductsPopup, popupData);
}

/**
 * Determine which offer items will be edited in the multiposition based on the selected or all offer items
 * @param offerItems - All offer items in the offer
 * @param selectedOfferItems - Selected offer items, if any
 * @return - Offer item entities that will be used in multiposition
 */
export function determineWhichOfferItemsWillBeUsed(
    offerItems: OfferItem[],
    selectedOfferItems: TransformedTableOfferItem[]
) {
    const selectedOfferItemIds = selectedOfferItems.map((selectedOfferItem) => selectedOfferItem.id);
    const offerItemsToBeUsed = offerItems.filter((offerItem) => selectedOfferItemIds.includes(offerItem.id));

    return offerItemsToBeUsed.length > 0 ? offerItemsToBeUsed : offerItems;
}

/**
 * Extract offer item ids for updating row numbers, offer item ids for multiposition, and product form
 * and product ids for fetching from backend
 * @param offerItemsToBeUsed - The offer item entities that will be used in multiposition
 * @param offerItemsAndOfferTitles - All offer items and offer titles that exist on the offer
 * @return - An object with the mentioned values
 */
export function extractProductProductFormAndOfferItemsIds(
    offerItemsToBeUsed: OfferItem[],
    offerItemsAndOfferTitles: Array<OfferItem | OfferTitle>
) {
    const offerItemIdsForUpdatingRowNumbers = mapAllOfferItemsForUpdatingRowNumbers(offerItemsAndOfferTitles);
    const offerItemIds = extractOfferItemIds(offerItemsToBeUsed);
    const productFormAndProductIds = extractAndFetchProductsAndProductFormIds(offerItemsToBeUsed);

    return {
        offerItemIdsForUpdatingRowNumbers,
        offerItemIds,
        productFormAndProductIds,
    };
}

/**
 * Extract the current state of items from the OfferItemsTable and DataTable, filter the ones that need to be deleted
 * and update all offer items and offer titles
 * @param offerItemsTableReference - The reference to the OfferItemsTable component
 * @param selectedOfferId - The currently selected offer id
 * @param offerItemsToBeDeleted - The ids of the offer items that are selected to be deleted
 * @return - An empty promise
 */
export async function updateRowNumbers(
    // @ts-ignore
    offerItemsTableReference: OfferItemsTable,
    selectedOfferId: string,
    offerItemsToBeDeleted?: string[]
) {
    const filteredAndSortedOfferItems: TransformedTableOfferItem[] = filterOfferItemsToBeDeleted(
        offerItemsTableReference,
        offerItemsToBeDeleted
    );

    try {
        await generateAndUpdateRowNumbers(filteredAndSortedOfferItems, selectedOfferId);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Extract the offer items from the DataTable and filter out the offer items that should be deleted
 * @param offerItemsTableReference - The reference to the OfferItemsTable component
 * @param offerItemsToBeDeleted - The ids of the offer items that are selected to be deleted
 * @return - An array of offer items that should have their rows updated
 */
export function filterOfferItemsToBeDeleted(
    // @ts-ignore
    offerItemsTableReference: OfferItemsTable,
    offerItemsToBeDeleted?: string[]
) {
    const dataTableRef = offerItemsTableReference.$refs.dataTable as DataTable;
    // accessing a variable inside a 3rd party component which cant be typed
    // @ts-ignore
    let filteredAndSortedOfferItems: TransformedTableOfferItem[] = dataTableRef.processedData;

    if (offerItemsToBeDeleted != null) {
        filteredAndSortedOfferItems = filteredAndSortedOfferItems.filter((offerItem) => {
            return !offerItemsToBeDeleted.includes(offerItem.id);
        });
    }

    return filteredAndSortedOfferItems;
}

/**
 * Generate the payload for updating row numbers for offer titles and items
 * It will also update the offer because updating row numbers returns no data in the body
 * @param filteredAndSortedOfferItems - An array of offer items and titles that need to be updated
 * @param selectedOfferId - The currently selected offer id
 * @return - An empty promise
 */
export async function generateAndUpdateRowNumbers(
    filteredAndSortedOfferItems: TransformedTableOfferItem[],
    selectedOfferId: string
) {
    const { offerItemRowNumbers, offerTitleRowNumbers } = generateArraysForUpdatingRowNumbers(
        filteredAndSortedOfferItems
    );

    try {
        await Promise.all([
            OfferItem.updateRowNumbers(offerItemRowNumbers),
            OfferTitle.updateRowNumbers(offerTitleRowNumbers),
        ]);
        await Offer.getForOffersTab(selectedOfferId);
    } catch (error) {
        throw error;
    }

    return Promise.resolve();
}

/**
 * Prepares the data for updating an offer items
 * Then it opens either the material popup or the detailed configurator
 * @param editOfferItemSettings - All settings needed to update an offer item
 * @return - An empty promise
 */
export async function prepareAndEditOfferItem(editOfferItemSettings: EditOfferItemSettings) {
    const offerItem = await OfferItemRepository.getById(editOfferItemSettings.offerItemId);
    if (offerItem == null) {
        EventBus.$emit(EventBusEvents.openAddOfferTitlePopup, editOfferItemSettings.offerItemId);

        return;
    }
    const isOfferItemMaterial = offerItem.offerItemOfferItemType === ProductTypes.MATERIAL;

    if (isOfferItemMaterial) {
        openMaterialPopup(offerItem, editOfferItemSettings);
    } else {
        openOfferItemInDetailedConfigurator(editOfferItemSettings, {
            productFormId: offerItem.offerItemProductSystem.product.productForm.id,
            productId: offerItem.offerItemProductSystem.product.id,
            productSystemId: offerItem.offerItemProductSystem.id,
            offerItemId: editOfferItemSettings.offerItemId,
            rowNumber: offerItem.rowNumber,
        });
    }

    return Promise.resolve();
}

/**
 * Opens the material popup with the correct data
 * @param offerItem - The offer item entity
 * @param editOfferItemSettings - All settings needed to update an offer item
 */
export function openMaterialPopup(offerItem: OfferItem, editOfferItemSettings: EditOfferItemSettings) {
    const popupData: PopupEventData = {
        popupEvent: PopupEvents.openAddMaterials,
        data: {
            materialId: offerItem.offerItemMaterialId,
            projectId: editOfferItemSettings.projectId,
            offerId: editOfferItemSettings.selectedOfferId,
            offerItemId: editOfferItemSettings.offerItemId,
            clientsPaymentTypeId: editOfferItemSettings.clientsPaymentTypeId,
            offerItems: filterProductsFromOfferItems(editOfferItemSettings.offerItems, false),
        },
    };
    EventBus.$emit(EventBusEvents.openMaterialsPopup, popupData);
}

/**
 * Opens the detailed configurator with the correct data
 * @param editOfferItemSettings - All settings needed to update an offer item
 * @param configuratorSettings - All settings needed to open the configurator
 */
export async function openOfferItemInDetailedConfigurator(
    editOfferItemSettings: EditOfferItemSettings,
    configuratorSettings: ConfiguratorSettings
) {
    const params = {
        productFormId: configuratorSettings.productFormId,
        productSystemId: configuratorSettings.productSystemId,
        productId: configuratorSettings.productId,
        offerItemId: configuratorSettings.offerItemId ? configuratorSettings.offerItemId : undefined,
        rowNumber: configuratorSettings?.rowNumber,
    };

    await router.push({
        name: RouteNames.configurator,
        // @ts-ignore
        params,
        query: {
            projectId: editOfferItemSettings.projectId,
            offerId: editOfferItemSettings.selectedOfferId,
            clientId: editOfferItemSettings.clientId,
            productSystemName: configuratorSettings.productSystemCode,
        },
    });

    return Promise.resolve();
}

/**
 * Deletes the selected offer items and updates the row numbers
 * @param selectedOfferItems - Offer items that will be deleted
 * @param selectedOfferId - Currently selected offer id
 * @param offerItemsTableReference - The reference to the OfferItemsTable component
 * @return - An empty promise
 */
export async function deleteOfferItemsAndUpdateRowNumbers(
    selectedOfferItems: TransformedTableOfferItem[],
    selectedOfferId: null | string,
    // @ts-ignore
    offerItemsTableReference: OfferItemsTable
) {
    if (selectedOfferItems.length <= 0 || selectedOfferId == null) {
        return Promise.reject(new Error('No offer items are selected or the offer does not exist'));
    }

    try {
        const offerItemsToBeDeleted = selectedOfferItems.map((offerItem: TransformedTableOfferItem) => offerItem.id);

        await updateRowNumbers(offerItemsTableReference, selectedOfferId, offerItemsToBeDeleted);

        await Promise.all(
            selectedOfferItems.map((offerItem: TransformedTableOfferItem) => {
                return deleteTheCorrectEntity(offerItem.type, offerItem.id);
            })
        );
        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Deletes the offer item price manipulations on the given offer items
 * @param selectedOfferItems - Offer items that will be deleted
 * @param selectedOfferId - Currently selected offer id
 * @param offerItemsAndOfferTitles - All offer items and offer titles that exist on the offer
 * @return - An empty promise
 */
export async function deleteOfferItemPriceManipulations(
    selectedOfferItems: TransformedTableOfferItem[],
    selectedOfferId: null | string,
    offerItemsAndOfferTitles: Array<OfferItem | OfferTitle>
) {
    if (selectedOfferId == null) {
        return Promise.reject(new Error('No offer id was provided'));
    }
    const shouldEveryItemDelete = selectedOfferItems.length === offerItemsAndOfferTitles.length;

    try {
        if (shouldEveryItemDelete) {
            await OfferItemPriceManipulation.deleteAllExisting(selectedOfferId);
        } else {
            await deleteOfferItemPriceManipulationsOnSelectedOfferItems(selectedOfferItems);
        }
        await Offer.getForOffersTab(selectedOfferId);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Extracts the offer item price manipulation ids from the given offer items and deletes them
 * @param selectedOfferItems - Offer items to have their price manipulations deleted
 * @return - An empty promise
 */
export async function deleteOfferItemPriceManipulationsOnSelectedOfferItems(
    selectedOfferItems: TransformedTableOfferItem[]
) {
    let allOfferItemsPriceManipulations = [];
    try {
        allOfferItemsPriceManipulations = selectedOfferItems
            .map((offerItem: TransformedTableOfferItem) => {
                return offerItem.sellingPriceManipulationDetails
                    .filter((sellingPriceManipulation: any) => {
                        return sellingPriceManipulation.isEditable === true;
                    })
                    .map((sellingPriceManipulation: any) => sellingPriceManipulation.id);
            })
            .flat();
    }
    catch (e) {
        throw e;
    }

    try {
        await Promise.all(
            allOfferItemsPriceManipulations.map((sellingPriceManipulationId: string) => {
                return OfferItemPriceManipulation.deleteExisting(sellingPriceManipulationId);
            })
        );
        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Duplicates the selected offer items
 * @param selectedOfferItems - Offer items that will be deleted
 * @param selectedOfferId - Currently selected offer id
 * @param offerItemsTableReference - The reference to the OfferItemsTable component
 * @param timesToDuplicate - Number of times to duplicate the offer items
 * @return - An empty promise
 */
export async function duplicateSelectedOfferItems(
    selectedOfferItems: TransformedTableOfferItem[],
    selectedOfferId: null | string,
    // @ts-ignore
    offerItemsTableReference: OfferItemsTable,
    timesToDuplicate: number
) {
    if (selectedOfferId == null) {
        return Promise.reject(new Error('No offer id was provided'));
    }
    try {
        await updateRowNumbers(offerItemsTableReference, selectedOfferId);
        for (let i = 0; i < timesToDuplicate; i++) {
            for await (const offerItem of selectedOfferItems) {
                await duplicateTheCorrectEntity(
                    (offerItem as TransformedTableOfferItem).type,
                    (offerItem as TransformedTableOfferItem).id
                );
            }
        }
        await Offer.getForOffersTab(selectedOfferId);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Creates an offer titles and updates the offer titles and items, so it fetches the new row numbers
 * @param name - The name of the new offer title
 * @param offerId - Currently selected offer id
 * @return - An empty promise
 */
export async function createOfferTitle(name: string, offerId: string) {
    try {
        await OfferTitle.createNew({
            description: name,
            offerId,
        });
    } catch (error) {
        showErrorNotifications(error as AxiosError<any>);
        return Promise.reject(error);
    }

    notification.success({
        message: i18n.t('Naslov je uspješno stvoren') as string,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Updates the offer title
 * @param name - The name of the new offer title
 * @param offerTitleId - The id of the offer title to be updated
 * @return - An empty promise
 */
export async function updateOfferTitle(name: string, offerId: string, offerTitleId: string) {
    try {
        await OfferTitle.updateExisting(offerTitleId, {
            description: name,
            offerId,
        });
    } catch (error) {
        showErrorNotifications(error as AxiosError<any>);
        return Promise.reject(error);
    }

    notification.success({
        message: i18n.t('Title successfully changed') as string,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Gets the offer title by id
 * @param offerTitleId - The id of the offer title to be updated
 * @return - Current value
 */
export async function getOfferTitle(offerTitleId: string) {
    let title;

    try {
        title = await OfferTitle.getById(offerTitleId);
    } catch (error) {
        showErrorNotifications(error as AxiosError<any>);
        return Promise.reject(error);
    }

    return Promise.resolve(title);
}
