
import { Component, Prop, Vue } from 'vue-property-decorator';
import InstallationAndDeliveryDetails from '@/components/views/NewProjectView/Offer/InstallationAndDeliveryDetails.vue';
import BillingAndDeliveryAddresses from '@/components/views/NewProjectView/Lead/BillingAndDeliveryAddresses.vue';
import { Divider } from 'ant-design-vue';
import InstallationAndDeliveryForm from '@/components/views/NewProjectView/Offer/InstallationAndDeliveryForm.vue';
import Offer from '@/models/Offer';


@Component({
  name: 'InstallationAndDeliveryDetailsAndForm',
  components: {
    InstallationAndDeliveryForm,
    BillingAndDeliveryAddresses,
    InstallationAndDeliveryDetails,
    Divider,
  },
})
export default class InstallationAndDeliveryDetailsAndForm extends Vue {
  @Prop({ default: null }) private projectId!: string;
  @Prop({ default: false }) private isEditMode!: boolean;
  @Prop({ default: false }) private isLoading!: boolean;
  @Prop({ required: true }) private selectedOffer!: Offer | null;
  
  private get montage() {
    if (this.selectedOffer == null) {
      return null;
    }
    return this.selectedOffer.offerMontage;
  }

  public async saveForm() {
    const installationAndDeliveryForm = this.$refs
      .installationAndDeliveryForm as InstallationAndDeliveryForm;

    await installationAndDeliveryForm.onSubmit();
  }
}
