
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import Project from '@/models/Project';
import { Divider } from 'ant-design-vue';
import Offer from '@/models/Offer';

@Component({
    name: 'PaymentDetails',
    components: { TwoRowWithTag, Divider },
})
export default class PaymentDetails extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private get bankAccount() {
        if (this.project.bankAccount == null) {
            return '-';
        }
        const bankAccount = this.project.bankAccount;
        return `${bankAccount.bankName}: ${bankAccount.accountNumber}`;
    }

    private get taxRate() {
        return this.selectedOffer && this.selectedOffer.tax ? this.selectedOffer.tax : '-';
    }

    private get expirationDate() {
        return this.selectedOffer && this.selectedOffer.expirationDate ? this.selectedOffer.expirationDate : '-';
    }

    private get paymentType() {
        return this.selectedOffer && this.selectedOffer.paymentType ? this.selectedOffer.paymentType.name : '-';
    }
}
