<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.38673 3.08268C8.59312 1.96192 7.39563 1.33268 5.99935 1.33268C3.42202 1.33268 1.33268 3.42202 1.33268 5.99935H0.166016C0.166016 2.77769 2.77769 0.166016 5.99935 0.166016C7.63515 0.166016 9.07637 0.855814 10.0827 2.07372V1.33268H11.2493V4.24935H8.33268V3.08268H9.38673ZM2.61197 8.91602C3.40558 10.0368 4.60307 10.666 5.99935 10.666C8.57668 10.666 10.666 8.57668 10.666 5.99935H11.8327C11.8327 9.22101 9.22101 11.8327 5.99935 11.8327C4.36355 11.8327 2.92233 11.1429 1.91602 9.92498V10.666H0.749349V7.74935H3.66602V8.91602H2.61197ZM5.99954 8.33172C5.67727 8.33172 5.41602 8.07055 5.41602 7.74838C5.41602 7.42622 5.67727 7.16505 5.99954 7.16505C6.32181 7.16505 6.58307 7.42622 6.58307 7.74838C6.58307 8.07055 6.32181 8.33172 5.99954 8.33172ZM5.41774 3.08172H6.58479V6.58172H5.41774V3.08172Z" fill="#8C8C8C"/>
    </svg>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';

    @Component({
        name: 'ArrowInformationIcon',
    })
    export default class ArrowInformationIcon extends Vue {
    }
</script>

<style lang="scss" scoped>

</style>
